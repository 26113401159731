<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="top-tv">
      <div class="tv-top-txt-wrap">
        <h1 class="txt60 tv">Awesome TV Channels and Apps</h1>
        <p class="txt30 tv">Live 24/7 and On Demand</p>
        <p class="txt24">Available on SmartTVs, streaming devices and cable.</p>
      </div>
    </div>

    <div class="tv-ch-sec">
      <div class="tv-ch-box">
        <video playsinline="" style="pointer-events: none" autoplay="" muted="" loop="" class="small-vid">
          <source src="../video/BRB.mp4" type="video/mp4" />
        </video>
        <p class="txt20 light">
          <b>BRB: Travel & Food</b>™<br> BRB (Be Right Back) begins when you set your phone to ‘do not disturb’ and focus on enjoying life. Refreshing takes on travel, food, and play.

        </p>
      </div>
      <div class="tv-ch-box">
        <video playsinline="" style="pointer-events: none" autoplay="" muted="" loop="" class="small-vid">
          <source src="../video/PW_KIDS_200_Website_Loop.mp4" type="video/mp4" />
        </video>
        <p class="txt20 light">
          <b>PW Kids</b><br> The new home for real-life kids and families, gamers and fun.

        </p>
      </div>
      <div class="tv-ch-box">
        <video playsinline="" style="pointer-events: none" autoplay="" muted="" loop="" class="small-vid">
          <source src="../video/NINJAKIDZ_Website_Loop.mp4" type="video/mp4" />
        </video>
        <p class="txt20 light">
          <b>Ninja Kidz TV</b><br> Awesome family-friendly channel for all ages featuring action parodies, amazing adventures and lessons to master your skills!


        </p>
      </div>
    </div>
<div class="big-vid-wrap">
      <div class="big-vid"><iframe src='https://vimeo.com/showcase/10376713/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe></div>
  
</div>

    <div class="pat-bg">
      <div class="avod-sec" id="AVOD">
        <div class="avod-img">
          <p class="avod-head">AVOD</p>
          <img src="../assets/remote.jpg" />
        </div>
        <div class="avod-txt-box">
          <p class="txt18">
The Play.Works AVOD framework allows Play.Works to simultaneously publish AVOD apps to every distribution point in the world. With over 100 AVOD apps live on partners like Comcast, Cox, Videotron, Roku, Vizio and more, Play.Works continues to innovate and shake up the TV landscape by bringing new voices to the big screen.
          </p>
          <!-- <a href="" class="btn bigger wide">More</a> -->
        </div>
      </div>
      <div class="fast-sec" id="FAST">
        <div class="fast-txt-wrap">
          <h3 class="txt90 red">FAST</h3>
          <img src="../assets/pattern1 blue copy 6.svg" class="fast-icon" />
        </div>
        <p class="txt20 light">
          Live TV is back and Play.Works is operating 24X7 linear channels with some of the freshest programming on TV. Check out channels distributed across platforms such as Vizio, Plex, Xiaomi and
          more. Stay tuned as TV is about to get fun again.

        </p>
      </div>
    </div>
    <div class="faq-page-wrap">
      <div class="side-head-wrap top-page">
        <h3 class="txt60">Our Stars</h3>
      </div>

      <div class="faq-page-box stars" id="stars">
        <p class="txt20 light">
          Play.Works is bringing new voices to new audiences on Connected TV platforms. Meet some of the PlayWorks talent:
        </p>

      </div>
    </div>
    <div class="stars-wrap">
      <div class="star-box" v-for="box in stars" :key="box._id">
        <Starbox v-bind="box" @toggleOpen="handleToggle" @toggleOff="closeAllBoxes" />
        <div v-if="box.openVal" :style="{ height: `${ghostBoxHeight}px` }" class="ghost" />
      </div>
    </div>
    <div v-if="loadMoreMessage !== 'Loaded'" class="center">
      <button @click="loadMore" class="btn bigger">
        {{ loadMoreMessage }}
      </button>
    </div>
    <div class="prime-sec" id="Primetime">
      <div class="prime-wrap">
        <div class="prime-box">
          <h3 class="txt60 blue-txt">Ready for Primetime?</h3>
          <img src="../assets/pattern1 copy 11.svg" class="prime-big-icon" />
          <p class="txt20 light">
            Play.Works is on the lookout for original content creators and new choices with something to say. We have a sweet spot for gaming,
            eating, travel, adventure, cooking, playing, kids and just having
            fun. Play.Works greatly expands distribution and monetization,
            bringing your content to large television audiences.
          </p>
          <p class="txt20 light">
            We've been in this space for a decade, we know the nuances, the tech
            and the players. Our scrappy and dedicated Emmy Award™ winning team
            knows TV inside and out, and our network streams our
            channels and apps across smart TVs, streaming devices, and cable.
          </p>
          <div class="prime-line">
            <div class="prime-icon"><img src="../assets/icon-blue1.svg" /></div>
            <p>Reach of 200M+ US</p>
          </div>
          <div class="prime-line">
            <div class="prime-icon"><img src="../assets/icon-blue2.svg" /></div>
            <p>New Revenue Streams with eCPMs up to 10X higher than traditional social media</p>
          </div>
          <div class="prime-line">
            <div class="prime-icon"><img src="../assets/icon-blue3.svg" /></div>
            <p>
              Dedicated Award-Winning Television Executive and Production Teams
            </p>
          </div>
          <p class="txt20 light">
            If you’re a creator and have original content (we call it
            programming) that you think would be great on TV, we’d love to hear
            from you.
          </p>
          <!-- <router-link to="/lets-play" class="btn">Contact Us</router-link> -->
          <a href="mailto:creators@play.works" target="_blank" class="cont-small-link">creators@play.works</a>
        </div>
      </div>
      <div class="games-bg prime"></div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Starbox from "@/components/Starbox.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
    Starbox,
  },
  data() {
    return {
      loadMoreMessage: "Load More",
      stars: [],
      ghostBoxHeight: 0,
    };
  },
  name: "TVChannels",
  methods: {
    async loadMore() {
      this.loadMoreMessage = "Loading...";
      const { data } = await this.$db
        .collection("creators")
        .sort("order")
        .everything()
        .perPage(200)
        .query("draft", "=", false)
        .get();
      this.stars = data.map((t) => {
        t.openVal = false;
        return t;
      });
      this.loadMoreMessage = "Loaded";
    },
    closeAllBoxes() {
      this.stars = this.stars.map((t) => {
        t.openVal = false;
        return t;
      });
      this.ghostBoxHeight = 0;
    },
    boxHeightToggle(_id) {
      let el = document.querySelector(`#s${_id}`);
      if (!el) return;
      let rect = el.getBoundingClientRect();
      this.ghostBoxHeight = rect.height;
    },
    handleToggle(val) {
      const { _id, openVal } = val;
      const indexFound = this.stars.findIndex((x) => x._id === _id);
      this.closeAllBoxes();
      this.stars[indexFound].openVal = openVal;
      setTimeout(() => {
        this.boxHeightToggle(_id);
      }, 0);
    },
  },
  async mounted() {
    const { data } = await this.$db
      .collection("creators")
      .sort("order")
      .everything()
      .query("draft", "=", false)
      .perPage(12)
      .get();
    this.stars = data.map((t) => {
      t.openVal = false;
      return t;
    });
  },
};
</script>
